
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiPaymentConfigGetList } from '@/api/setting'
@Component({
  components: {
    LsDialog
  }
})
export default class PayTemplate extends Vue {
    /** S Data **/
    paymentConfigData: Array<Object> = []
    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getPaymentConfigList () {
      apiPaymentConfigGetList()
        .then((res: any) => {
          this.paymentConfigData = res
        })
        .catch(() => {
          this.$message.error('数据初始化失败，请刷新重载！')
        })
    }

    goPayConfigEdit (row: any) {
      this.$router.push({
        path: '/setting/payment/pay_config_edit',
        query: {
          id: row.id
        }
      })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getPaymentConfigList()
    }
}
